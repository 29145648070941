/* ::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background-color: #9b9595;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ffffff;
}

::-webkit-scrollbar-track:hover {
  background-color: #f0f0f0;
}

.MuiInputBase-input:focus {
  background-color: #ffffff !important;
  box-shadow: "none" !important;
}

.MuiPagination-outlined {
  border-radius: 12px !important;
} */

@font-face {
  font-family: "Inter";
  font-weight: 100;
  src: url("./assets/fonts/Inter-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 200;
  src: url("./assets/fonts/Inter-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 300;
  src: url("./assets/fonts/Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: url("./assets//fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  src: url("./assets//fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 600;
  src: url("./assets//fonts/Inter-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 700;
  src: url("./assets//fonts/Inter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 800;
  src: url("./assets//fonts/Inter-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 900;
  src: url("./assets//fonts/Inter-Black.ttf") format("truetype");
}

@font-face {
  font-family: "SanSerif";
  font-weight: 200;
  src: url("./assets/fonts/SourceSerif4-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "SanSerif";
  font-weight: 300;
  src: url("./assets/fonts/SourceSerif4-Light.ttf") format("truetype");
}

@font-face {
  font-family: "SanSerif";
  font-weight: 400;
  src: url("./assets//fonts/SourceSerif4-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SanSerif";
  font-weight: 500;
  src: url("./assets//fonts/SourceSerif4-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "SanSerif";
  font-weight: 600;
  src: url("./assets//fonts/SourceSerif4-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "SanSerif";
  font-weight: 700;
  src: url("./assets//fonts/SourceSerif4-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "SanSerif";
  font-weight: 800;
  src: url("./assets//fonts/SourceSerif4-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "SanSerif";
  font-weight: 900;
  src: url("./assets//fonts/SourceSerif4-Black.ttf") format("truetype");
}

body {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
}
